<template>
  <div id="login" class="hi100 login-phone">
    <div class="uf uf-ac uf-pc hi100 pr-2 pl-2 " >
      <div class="" v-if="winWidth>1336" style="margin-right: 10rem;">
        <img src="~@/assets/img/login-left.png" alt="">
      </div>
      <div class="login-box pr-5 pl-5 pt-5 ">
        <div class="logo-box uf uf-pc" >
          <div class="wrap uf uf-ac uf-pc wi100" style="cursor: pointer">
            <img src="~@/assets/img/logo-sp.png" alt="">
          </div>
        </div>
        <div class="tac f18 fwb">用户注册</div>
        <template>
          <el-form class="mt-4" :model="dataForm" :rules="dataRule" ref="dataForm"
                   status-icon>
            <el-form-item prop="mobile">
              <el-row :gutter="10">
                <el-col>
                  <el-popover
                    placement="right"
                    title="提示"
                    width="280"
                    trigger="focus"
                    content="登录时用户名同手机号">
                    <el-input prefix-icon="el-icon-mobile-phone" v-model="dataForm.mobile" placeholder="手机号"
                              clearable slot="reference"></el-input>
                  </el-popover>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item prop="password">
              <el-row :gutter="35">
                <el-col>
                  <el-popover
                    placement="right"
                    title="密码规则"
                    width="280"
                    trigger="focus"
                    content="密码应为6~16个字符，包含字母、数字和特殊字符(.#@!~%^&*)">
                    <el-input prefix-icon="el-icon-lock" v-model="dataForm.password" type="password" placeholder="密码"
                              clearable slot="reference"></el-input>
                  </el-popover>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item prop="checkPassword">
              <el-row :gutter="35">
                <el-col>
                  <el-input prefix-icon="el-icon-lock" v-model="dataForm.checkPassword" type="password" placeholder="确认密码"
                            clearable></el-input>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item prop="name">
              <el-row :gutter="10">
                <el-col>
                  <el-input prefix-icon="el-icon-user" v-model="dataForm.name" placeholder="昵称" clearable></el-input>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item prop="email">
              <el-row :gutter="10">
                <el-col>
                  <el-input prefix-icon="el-icon-message" v-model="dataForm.email" placeholder="邮箱" clearable></el-input>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item v-if="!isH5">
              <div v-if="isMobile"><slider-mobile ref="slider"></slider-mobile></div>
              <div v-else><slider ref="slider"></slider></div>
            </el-form-item>
            <el-form-item prop="smsCode">
              <el-row :gutter="10">
                <el-col :span="14">
                  <el-input prefix-icon="el-icon-key" v-model="dataForm.smsCode" placeholder="短信验证码"></el-input>
                </el-col>
                <el-col :span="10">
                  <div class="qrcode">
                    <template v-if="smsSuc">
                      <el-button type="danger">{{ smsTime }}</el-button>
                    </template>
                    <template v-else>
                      <el-button type="text" @click="getMsgCode">获取短信验证码</el-button>
                    </template>
                  </div>
                </el-col>
              </el-row>
            </el-form-item>
            <el-button type="primary" class="wi100" @click="doSub" :loading="loading">注册</el-button>
          </el-form>
        </template>
        <div class="uf uf-pc mt-4 mb-3">
          <div @click="goLogin" class="c-base cp">已有账号！去登录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {getUUID, encode64, userSystemAgent} from '@/utils'
  import Slider from './components/slider'
  import SliderMobile from './components/slider-mobile'
  import {isMobile, isEmail} from '../../utils/validate'

  export default {
    name: 'register',
    components: {Slider,SliderMobile},
    data () {
      const checkSmsCode = (rule, value, callback) => {
        if (value === '') {
          return callback(new Error('短信验证码不能为空'))
        } else {
          callback()
        }
      }
      const checkMobile = (rule, value, callback) => {
        if (value === '') {
          return callback(new Error('请输入手机号码'))
        } else if (!isMobile(value)) {
          return callback(new Error('手机号码格式不正确'))
        } else {
          callback()
        }
      }
      const validatePassword1 = (rule, value, callback) => {
        const pwd = /(?=.*[a-zA-Z])(?=.*\d)(?=.*[.#@!~%^&*])[a-zA-Z\d.#@!~%^&*]{6,16}/
        if (value === '') {
          callback(new Error('密码不能为空'))
        } else if (!pwd.test(value)) {
          callback(new Error('密码应为6~16个字符，包含字母、数字和特殊字符(.#@!~%^&*)'))
        } else {
          callback()
        }
      }
      const checkEmail = (rule, value, callback) => {
        if (value === '') {
          return callback(new Error('请输入邮箱地址'))
        } else if (!isEmail(value)) {
          return callback(new Error('邮箱地址格式不正确'))
        } else {
          callback()
        }
      }
      const validatePassword = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'))
        } else if (value !== this.dataForm.password) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        step: 1,
        dataForm: {
          name: '',
          password: '',
          checkPassword: '',
          uuid: '',
          captcha: '',
          mobile: '',
          email: '',
          smsCode: '',
          enterpriseName: '',
          corporation: '',
          orgCode: '',
          orgName: '',
          registerTime: null,
          socialCode: ''
        },
        captchaPath: '',
        dataRule: {
          name: [
            {required: true, message: '昵称不能为空', trigger: 'blur'}
          ],
          password: [
            {required: true, validator: validatePassword1, trigger: 'blur'}
          ],
          checkPassword: [
            {required: true, validator: validatePassword, trigger: 'blur'}
          ],
          // captcha: [
          //   {required: true, message: '验证码不能为空', trigger: 'blur'}
          // ],
          mobile: [
            {required: true, validator: checkMobile, trigger: 'blur'}
          ],
          email: [
            {required: true, validator: checkEmail, trigger: 'blur'}
          ],
          smsCode: [{validator: checkSmsCode, trigger: 'blur'}
          ]
        },
        loading: false,
        smsSuc: false,
        isMobile: false,
        smsTime: '',
        isH5: false
      }
    },
    computed: {
      winWidth :{
        get () { return  document.documentElement.clientWidth }
      },
    },
    created () {
      //判断是否移动端
      let ua = userSystemAgent()
      if (ua === 'isAndroid'||ua ==='isIOS') {
        this.isMobile = true
      }
      this.$nextTick(() => {
        this.isH5 = this.$route.params.h5 || ''
      })
    },
    methods: {
      doSub () {
        let _this = this
        this.$refs['dataForm'].validate((valid) => {
          if (valid && !this.loading) {
            this.loading = true
            this.$http({
              url: _this.$http.adornUrl(this.$api.SYS.USER.REGISTER),
              method: 'post',
              data: _this.$http.adornData({
                'name': _this.dataForm.name,
                'mobile': _this.dataForm.mobile,
                'smsCode': encode64(this.dataForm.smsCode),
                'smsTerminal': 'SMS_TERMINAL_REGISTER',
                'password': encode64(this.$encryptECB(_this.dataForm.password)),
                'email': _this.dataForm.email
                // 'enterpriseName': _this.dataForm.enterpriseName,
                // 'corporation': _this.dataForm.corporation,
                // 'orgCode': _this.dataForm.orgCode,
                // 'orgName': _this.dataForm.orgName,
                // 'registerTime': _this.dataForm.registerTime,
                // 'socialCode': _this.dataForm.socialCode,
                // 'accountType': 'USER'
              })
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.$message.success('注册成功，请登录！')
                setTimeout(function () {
                  _this.$router.push({name: _this.isH5 ? 'h5-login' : 'login'})
                }, 1000)
              } else {
                // this.getCaptcha()
                // this.dataForm.captcha = ''
                this.$message.error(data.msg)
                this.$nextTick(() => {
                  this.loading = false
                })
              }
            })
          }
        })
      },
      lastStep () {
        this.step = 1
        this.dataForm.smsCode = ''
        this.dataForm.captcha = ''
        this.smsSuc = false
        this.getCaptcha()
        this.$refs['dataForm'].clearValidate()
      },
      orgSelect (orgCode, orgName) {
        this.dataForm.orgCode = orgCode
        this.dataForm.orgName = orgName
      },
      // 校验短信验证码是否正确
      // verifySmsCode () {
      //   this.$refs['dataForm'].validate((valid) => {
      //     if (valid) {
      //       this.loading = true
      //       this.$http({
      //         url: this.$http.adornUrl(this.$api.SYS.V_CODE.VERIFY),
      //         method: 'post',
      //         data: this.$http.adornData({
      //           // 'uuid': this.dataForm.uuid,
      //           // 'captcha': this.dataForm.captcha,
      //           'mobile': this.dataForm.mobile,
      //           'smsCode': encode64(this.dataForm.smsCode),
      //           'smsTerminal': 'SMS_TERMINAL_REGISTER'
      //         })
      //       }).then(({data}) => {
      //         if (data && data.code === 0) {
      //           this.doSub()
      //           this.loading = false
      //           this.$refs['dataForm'].clearValidate()
      //         } else {
      //           this.loading = false
      //           this.$message.error(data.msg)
      //           this.getCaptcha()
      //         }
      //       })
      //     }
      //   })
      // },
      // 获取验证码
      getCaptcha () {
        this.dataForm.uuid = getUUID()
        this.captchaPath = this.$http.adornUrl(`/captcha.jpg?uuid=${this.dataForm.uuid}`)
      },
      getMsgCode () {
          // this.$message.error('短信功能未启用！')
        if(this.$refs['slider']) {
          // 未通过验证时，提示错误信息并返回
          if (!this.$refs['slider'].confirmSuccess) {
            this.$message.error('请拖动滑块验证')
            return
          }
        }
        let _this = this
        this.$refs['dataForm'].validateField('mobile', (mobileMsg) => {
          if (mobileMsg === '') {
            _this.$http({
              url: _this.$http.adornUrl(_this.$api.SYS.VALID_CODE),
              method: 'post',
              data: _this.$http.adornData({
                'mobile': _this.dataForm.mobile,
                'smsTerminal': 'SMS_TERMINAL_REGISTER'
              })
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.$message.success('短信发送成功，请注意查收')
                _this.smsSuc = true
                _this.smsTime = '60s后可重新获取'
                _this.countdown(60)
              } else {
                this.$message.error(data.msg)
              }
            })
          }
        })
      },
      countdown (time) {
        if (time < 1) {
          this.smsSuc = false
          return
        }
        this.smsSuc = true
        this.smsTime = time + 's后可重新获取'
        setTimeout(this.countdown, 1000, --time)
      },
      // 前往注册
      goLogin () {
        this.$router.push({name: this.isH5 ? 'h5-login' : 'login'})
      }
    }
  }
</script>
<style scoped>
  #login {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*text-align: center;*/
    background-image: linear-gradient(50deg, #007bff, #51f9fb);
    height: 100vh;
  }

  body {
    width: 100%;
    height: 100vh;
  }

  .login-box {
    width: 30rem;
    min-height: 20rem;
    background: #ffffff;
    border-radius: 0.3rem;
    -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }

  .left-img img {
    width: 32rem;
    height: 32rem;
  }

  .logo-box {
    margin-top: -7rem;
  }

  .logo-box .wrap {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    background: #ffffff;
  }

  .logo-box .wrap img {
    width: 5rem;
    height: 5rem;
  }

  .qrcode {
    line-height: 100%;
    height: 100%;
    overflow: hidden;
  }

  .iconfont.icon-yanzhengma {
    font-size: 14px;
    margin-left: 6px;
    color: #C0C4CC;
  }

  .qrcode img {
    width: 100%;
    height: 100%;
    /*height: 2.5em;*/
    cursor: pointer;
    /*margin-top: 0.2rem;*/
  }
  /*@media only screen and (max-width: 500px) {*/
  /*  .logo-box {*/
  /*    margin-top: -3rem;*/
  /*  }*/
  /*}*/
  @media only screen and (max-width: 850px) {
    .login-phone{
      height: 50rem !important;
    }
  }
</style>
